<template>
  <v-container fluid>
    <v-card
      class="px-3"
    >
      <v-card-title>Vincular médico</v-card-title>
      <v-row align="start" justify="center">
        <v-col cols="6">
          <v-text-field 
            label="Clínica"
            :value="currentCustomer.name"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-combobox
            v-model="selected.docplanner.facility"
            :items="options.docplanner.facilities"
            item-text="label"
            label="Clinica DocPlanner"
            return-object
            clearable
            outlined
            persistent-hint
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row align="start" justify="center">
        <v-col cols="6">
          <v-combobox
            v-model="selected.clinic.doctor"
            :items="options.clinic.doctors"
            :hint="``"
            item-text="label"
            label="Medico Clinic"
            return-object
            clearable
            outlined
            persistent-hint
          ></v-combobox>
        </v-col>
        <v-col cols="6">
          <v-combobox
            v-model="selected.docplanner.doctor"
            :items="options.docplanner.doctors"
            hint="Infelizmente o DocPlanner não fornece o crm, por este motivo não podemos utiliza-lo para comparação."
            item-text="label"
            label="Medico DocPlanner"
            return-object
            clearable
            outlined
            persistent-hint
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row align="start" justify="center">
        <v-col cols="6">
          
        </v-col>
        <v-col cols="6">
          <v-combobox
            v-model="selected.docplanner.address"
            :items="options.docplanner.addresses"
            hint=""
            item-text="label"
            label="Endereços DocPlanner"
            return-object
            clearable
            outlined
            persistent-hint
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row align="start" justify="center">
        <v-col cols="6">
          
        </v-col>
        <v-col cols="6">
          <v-combobox
            v-model="selected.docplanner.service"
            :items="options.docplanner.services"
            hint=""
            item-text="label"
            label="Serviços DocPlanner"
            return-object
            clearable
            outlined
            persistent-hint
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row align="start" justify="center">
        <v-col cols="12">
          <div>
            <v-card-title>Convênios</v-card-title>
            <v-text-field
              v-model="insurancesTable.search"
              append-icon="mdi-magnify"
              label="Buscar convênio"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-data-table
            :items="this.insurancesTable.items"
            :headers="this.insurancesTable.headers"
            :search="insurancesTable.search"
            item-key="clinicId"
          >
            <template v-slot:item.actions="{ item }">
              <v-dialog v-model="item.editDialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <v-card class="px-3">
                  <v-card-title>Vincular convênio</v-card-title>
                  <v-row align="start" justify="center">
                    <v-col cols="6">
                      <v-text-field 
                        label="Convênio"
                        :value="item.clinic.label"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-combobox
                        v-model="item.docplanner"
                        :items="options.docplanner.insurances"
                        item-text="label"
                        label="DocPlanner Convênios"
                        return-object
                        clearable
                        filled
                        outlined
                        persistent-hint
                        solo
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="2">
                      <v-btn
                        color="primary"
                        @click="item.editDialog = false"
                      >
                        Confirmar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="2">
          <v-btn
            color="primary"
            @click="saveBind"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'BindFacilities',
    props: {
      /*
       / Loads provided bind.
      */
      _bindId: {
        type: String,
        required: false,
      },
      _bind: {
        type: Object,
        required: false,
      },
    },
    data: () => ({
      options: {
        docplanner: {
          facilities: [],
          addresses: [],
          services: [],
          doctors: [],
          insurances: null,
        },
        clinic: {
          doctors: [],
          insurances: null,
        },
      },
      selected: {
        docplanner: {
          facility: null,
          service: null,
          address: null,
          doctor: null,
        },
        clinic: {
          doctor: null,
        },
      },
      insurancesTable: {
        search: null,
        headers: [
          {
            text: 'ID Clinic',
            value: 'clinic.id',
          },
          {
            text: 'Nome Clinic',
            value: 'clinic.name',
          },
          {
            text: 'ID Docplanner',
            value: 'docplanner.id',
          },
          {
            text: 'Nome Docplanner',
            value: 'docplanner.name',
          },
          {
            value: 'actions',
            sortable: false,
          }
        ],
        items: [],
      } 
    }),
    computed: {
      ...mapGetters('auth', ['currentCustomer']),
    },
    watch: {
      'selected.docplanner.facility': function () {
        this.getDoctors()
      },
      'selected.docplanner.doctor': function () {
        this.getDocplannerDoctor(this.selected.docplanner.facility.id, this.selected.docplanner.doctor.id)
      },
      'selected.docplanner.address': function () {
        this.getDocplannerAddress(this.selected.docplanner.facility.id, this.selected.docplanner.doctor.id, this.selected.docplanner.address.id)
      },
      'selected.clinic.doctor': async function () {
        await this.getClinicDoctorInsurances(this.selected.clinic.doctor.calendarId)
      },
      'options.clinic.insurances': function () {
        this.parseInsuranceTableItems()
      },
    },
    async created () {
      await this.getDocplannerFacilities()
      await this.getDocplannerInsurances()
      if (this._bind && this._bind.bindId) {
        this.loadBind(this._bind)
      }
    },
    methods: {
      async getDocplannerFacilities() {
        const response = await this.$api.get('/docplanner/getFacilities')

        if (!response || !response.data || !response.data._items) {
          console.log('Erro buscando facilities', response)
          this.$util.$alert('Erro buscando facilities', 'error')
          return
        }

        const facilities = response.data._items.map(item => ({
            label: `${item.name}, ID: ${item.id}`,
            ...item,
        }))
        this.options.docplanner.facilities = facilities
      },

      async getDoctors() {
        const responseDocplanner = await this.$api.get(`/docplanner/getDoctors/${this.selected.docplanner.facility.id}`)
        const responseSpitzer = await this.$api.get(`/spitzer/getDoctors`)

        const docplannerDoctors = responseDocplanner.data._items.map((item) => ({
          label: `ID: ${item.id} - NOME: ${item.name} ${item.surname}`,
          ...item
        }))
        const clinicDoctors = responseSpitzer.data.result.map((item) => ({
          label: `CRM: ${item.crm} - NOME: ${item.name}`,
          ...item
        }))

        this.options.docplanner.doctors = docplannerDoctors
        this.options.clinic.doctors = clinicDoctors
      },

      async getDocplannerDoctor(facilityId, doctorId){
        const response = await this.$api.get(`/docplanner/getDoctors/${facilityId}/${doctorId}`)
        const addresses = []
        response.data.addresses._items.map(item => {
          addresses.push({
            label: `${item.name ? item.name + ', ' : ''}${item.street ? item.street + ', ' : ''}${item.post_code ? item.post_code + ', ' : ''}ID: ${item.id}`,
            ...item,
          })
        })
        this.options.docplanner.addresses = addresses
      },

      async getDocplannerAddress(facilityId, doctorId, addressId){
        const response = await this.$api.get(`/docplanner/getDoctors/${facilityId}/${doctorId}/${addressId}`)
        const services = []
        response.data._items.map(item => {
          services.push({
            label: `${item.name ? item.name + ', ' : ''}${item.description ? item.description + ', ' : ''}ID: ${item.id}`,
            ...item,
          })
        })
        this.options.docplanner.services = services
      },

      async getClinicDoctorInsurances(calendarId){
        const response = await this.$api.get(`/spitzer/getDoctors/${calendarId}`)
        const insurances = []
        response.data.result.codeHealthInsurance.map(item => {
          insurances.push({
            name: item.healthInsurance,
            id: item.codeHealthInsurance,
          })
        })
        this.options.clinic.insurances = insurances
      },
      async getDocplannerInsurances(){
        const response = await this.$api.get(`/docplanner/getInsuranceProviders`)

        if (!response || !response.data || !response.data._items) {
          console.log('Erro buscando facilities', response)
          this.$util.$alert('Erro buscando convênios', 'error')
          return
        }

        const insurances = []
        insurances.push({
          label: '0 - Particular',
          id: 0,
          name: 'Particular',
        })
        response.data._items.map(item => {
          insurances.push({
            label: `${item.insurance_provider_id} - ${item.name}`,
            id: item.insurance_provider_id,
            name: item.name,
          })
        })
        this.options.docplanner.insurances = insurances
      },
      async getBindedInsurances(){
        const response = await this.$api.get(`/bindedHealthInsurance`)
        const insurances = response.data.result
        return insurances
      },

      async parseInsuranceTableItems(){
        const bindedInsurances = await this.getBindedInsurances()
        const items = []
        this.options.clinic.insurances.map((item) => {
          const isBinded = bindedInsurances.find((bind) => `${bind.clinicId}` === `${item.id}`)

          items.push({
            clinic: {
              label: `${item.id} - ${item.name}`,
              ...item
            },
            docplanner: isBinded ? this.options.docplanner.insurances.find((docplannerItem) => `${isBinded.docplannerId}` === `${docplannerItem.id}`) : null,
            editDialog: false,
          })
        })
        this.insurancesTable.items = items
      },

      async saveBind() {
        console.log('insurances', this.insurancesTable.items)
        const { selected } = this
        const insurances = this.insurancesTable.items.map((item) => ({
          clinicId: item.clinic.id,
          docplannerId: item.docplanner ? item.docplanner.id : null,
        }))

        await this.$api.post('/bindDoctor', {
          docplannerDoctorId: selected.docplanner.doctor.id,
          docplannerAddressId: selected.docplanner.address.id,
          docplannerFacilityId: selected.docplanner.facility.id,
          docplannerServiceId: selected.docplanner.service.id,
          clinicCrm: selected.clinic.doctor.crm,
          clinicDoctorId: selected.clinic.doctor.id,
          clinicCustomerId: this.currentCustomer.id,
          clinicDoctorCalendarId: selected.clinic.doctor.calendarId,
          healthInsuranceProviders: insurances,
        })

        this.parseInsuranceTableItems()
        this.$emit('close')
      },

      async loadBind(bind) {
        this.selected.docplanner.facility = this.options.docplanner.facilities.find(a => a.id === bind.bind.docplanner.facilityId)
        this.selected.docplanner.doctor = {
          label: `${bind.docplanner.doctor.name ? bind.docplanner.doctor.name + ', ' : ''}${bind.docplanner.doctor.street ? bind.docplanner.doctor.street + ', ' : ''}${bind.docplanner.doctor.post_code ? bind.docplanner.doctor.post_code + ', ' : ''}ID: ${bind.docplanner.doctor.id}`,
          ...bind.docplanner.doctor,
        }
        this.selected.docplanner.address = {
          label: `${bind.docplanner.address.name ? bind.docplanner.address.name + ', ' : ''}${bind.docplanner.address.description ? bind.docplanner.address.description + ', ' : ''}ID: ${bind.docplanner.address.id}`,
          ...bind.docplanner.address,
        }
        this.selected.docplanner.service = {
          label: `${bind.docplanner.service.name ? bind.docplanner.service.name + ', ' : ''}${bind.docplanner.service.description ? bind.docplanner.service.description + ', ' : ''}ID: ${bind.docplanner.service.id}`,
          ...bind.docplanner.service,
        }
        this.selected.clinic.doctor = {
          label: `CRM: ${bind.clinic.doctor.crm} - NOME: ${bind.clinic.doctor.name}`,
          ...bind.clinic.doctor
        }
      },

      async getBinds(){
        // const response = await this.$api.get('/bindedDoctors')
      }
    }
  }
</script>
