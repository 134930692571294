<template>
  <v-container fluid>
    <binds />
  </v-container>
</template>

<script>
  import binds from '@/components/binds'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Home',
    data: function () {
      return {
      }
    },
    components: {
      binds: binds,
    },
    created() {
      this.getCurrentCustomer()
    },
    computed: {
      ...mapGetters('auth', ['currentCustomer', 'apiInstance'])
    },
    methods: {
      async getCurrentCustomer () {
        const facilitiesResponse = await this.$api.get('/spitzer/getFacilities')
        console.log(facilitiesResponse)
        const facilities = facilitiesResponse.data.result
        const customer = facilities.find((item) => item.id === this.apiInstance)
        this.$store.commit('auth/SET_CURRENT_CUSTOMER', customer)
      }
    }
  }
</script>
