<template>
  <v-container fluid>
    <v-card
      class="px-3"
    >
      <v-row justify="space-between">
        <v-card-title>Médicos vinculados</v-card-title>
        <v-btn
          color="primary"
          @click="newItem()"
        >
          Vincular Novo
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field 
            v-model="search"
          />
        </v-col>
      </v-row>
      <v-data-table
        :items="table.items"
        :headers="table.headers"
        :loading="table.loading"
        :search="search"
        item-key="clinicId"
      >
        <template v-slot:item.docplanner.address="{ value }">
          {{`${value && value.name ? value.name + ', ' : ''}${value && value.street ? value.street + ', ' : ''}${value && value.post_code ? value.post_code + ', ' : ''}ID: ${value && value.id}`}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="deleteItem(item)">Remover</v-btn>
          <v-btn @click="editItem(item)">Editar</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="bindDetailsDialog"
    >
      <bind-details v-if="bindDetailsDialog" :_bindId='bindId' :_bind='bind' @close="bindDetailsDialog = false; bindId = null; bind = null; getBindedDoctors()" />
    </v-dialog>
  </v-container>
</template>

<script>
import bindDetails from './bindDetails'

export default {
  name: 'bindedDoctors',
  components: {
    bindDetails: bindDetails,
  },
  data: function () {
    return {
      bindDetailsDialog: false,
      bindId: null,
      search: '',
      table: {
        headers: [
          {
            text: 'CRM',
            value: 'clinic.doctor.crm',
          },
          {
            text: 'Médico',
            value: 'clinic.doctor.name',
          },
          {
            text: 'Nome Docplanner',
            value: 'docplanner.doctor.name',
          },
          {
            text: 'Endereço Docplanner',
            value: 'docplanner.address',
          },
          {
            text: 'Serviço Docplanner',
            value: 'docplanner.service.name',
          },
          {
            value: 'actions',
            sortable: false,
          }
        ],
        items: [],
        loading: true,
      },
    };
  },
  created() {
    this.getBindedDoctors()
  },
  methods: {
    newItem: function () {
      this.bindDetailsDialog = true
      this.bindId = null
      this.bind = null
    },
    editItem: function (item) {
      this.bindDetailsDialog = true
      this.bindId = item.bindId
      this.bind = item
    },
    deleteItem: function (item) {
      this.$api.delete(`/bindedDoctors/${item.bindId}`)
      this.getBindedDoctors()
    },
    getBindedDoctors: async function () {
      this.table.loading = true
      const responseBindedDoctors = await this.$api.get(`/bindedDoctors`)
      const responseClinicDoctors = await this.$api.get(`/spitzer/getDoctors`)

      if (!responseBindedDoctors || !responseBindedDoctors.data || !responseBindedDoctors.data.result) {
        this.table.loading = false
        return
      }
      if (!responseClinicDoctors || !responseClinicDoctors.data || !responseClinicDoctors.data.result) {
        this.table.loading = false
        return
      }

      const items = await Promise.all(responseBindedDoctors.data.result.map(async (bindItem) => {
        try {
          let responseDocplannerDoctor = {}
          let responseDocplannerDoctorServices = {}
          
          try {
            responseDocplannerDoctor = await this.$api.get(`/docplanner/getDoctors/${bindItem.docplanner.facilityId}/${bindItem.docplanner.doctorId}`)
          } catch (error) {
             console.log(error, 'error getting doctor', bindItem)
          }

          try {
            responseDocplannerDoctorServices = await this.$api.get(`/docplanner/getDoctors/${bindItem.docplanner.facilityId}/${bindItem.docplanner.doctorId}/${bindItem.docplanner.addressId}`)
          } catch (error) {
            console.log(error, 'error getting doctor services', bindItem)
          }
          

          if (!responseDocplannerDoctor.data.addresses._items.find(item => item.id === bindItem.docplanner.addressId)){
            console.log(responseDocplannerDoctor.data.addresses._items)
          }
          return ({
            bindId: bindItem._id,
            docplanner: {
              doctor: {
                ...responseDocplannerDoctor.data,
                name: `${responseDocplannerDoctor.data.name} ${responseDocplannerDoctor.data.surname}`,
                specializations: responseDocplannerDoctor.data.specializations,
              },
              address: responseDocplannerDoctor.data.addresses._items.find(item => item.id === bindItem.docplanner.addressId),
              service: responseDocplannerDoctorServices.data._items.find(item => item.id === bindItem.docplanner.serviceId),
            },
            clinic: {
              doctor: responseClinicDoctors.data.result.find((item) => `${item.id}` === `${bindItem.clinic.doctorId}`)
            },
            bind: bindItem
          })
        } catch (error) {
          console.log(error, 'error getting doctor', bindItem)
          return ({
            bindId: bindItem._id
          })
        }
      }))
      this.table.items = items.sort()
      console.log(items)
      this.table.loading = false
    },
  }
}
</script>